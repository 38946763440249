<template>
  <div class="search">
    <div class="image">
      <van-image width="100px" style="padding: 20px" height="100px" fit="contain" :src="isda.url" />
    </div>
    <div class="theme-color" style="word-wrap: break-word;">
      {{ isda.tip }}
    </div>
    <div class="back-tip theme-color" style="word-wrap: break-word;" v-if="isda_state === 4">
      {{ isda_step_info }}
    </div>
    <van-button @click="handleGoIsda" style="margin-top: 30px;" class="btn" :type="isda_state === 4 ? 'primary' : undefined">
      {{ isda.btn_tip }}
    </van-button>
    <van-button class="btn" @click="handleGoHome">
      回到首页
    </van-button>
  </div>
</template>

<script>
import { Image as VanImage, Button } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";
import utils from "../../utils/util";

export default {
  name: "Home",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },
  data() {
    return {
      isdaJump: this.$store.state.user.isdaJump,
    };
  },
  beforeCreate() {
    const { query = {} } = this.$route;
    const { c, e } = query;
    const time = this.$store.state.user.cookieExpires || 0;
    this.$store.dispatch("handleLogin");
    if (Number(e) > Number(time)) {
      this.$store.commit("setIsdaJump", true);
      this.$util.setCookieValue(c, e);
    }
  },
  methods: {
    handleGoIsda() {
      this.$router.push({ path: "/isda/aggrement" });
    },
    handleGoHome() {
      if (this.isdaJump) {
        const url = `${window.location.protocol}//${utils.getCaHost()}/search/index`;
        window.location.replace(url);
      } else {
        this.$router.push({ path: "/isda/choose" });
      }
    },
  },
  computed: {
    isda() {
      const state = {};
      if (this.isda_state === 4) {
        state.tip = "抱歉，您的审批被退回！";
        state.btn_tip = "重签TRS收益互换衍生品服务";
        state.url =
          "https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/evaluation_failed.png";
        return state;
      }
      if (this.isda_state === 3) {
        state.tip = "恭喜，您的审批已通过！";
        state.btn_tip = "TRS收益互换衍生品服务查看";
        state.url =
          "https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_success.png";
        return state;
      }
      if (this.isda_state === 1) {
        state.tip = "已提交!";
        state.btn_tip = "TRS收益互换衍生品服务查看";
        state.url =
          "https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png";
        return state;
      }
      state.tip = "TRS收益互换审批中！";
      state.btn_tip = "TRS收益互换衍生品服务查看";
      state.url =
        "https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png";
      return state;
    },
  },
  setup() {
    const isda_state = ref("");
    const isda_step_info = ref("");
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "ISDA协议");
      store.commit("setStepsShow", false);
      store.commit("setTitleLeft", true);
      const res = await httpApi.getIsdaStateNoLog();
      if (res) {
        isda_state.value = res.data.isda_state;
        isda_step_info.value = res.data.isda_step_info;
      }
    });
    return {
      isda_state,
      isda_step_info,
    };
  },
};
</script>

<style lang="less">
.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search {
  padding-left: @space-0;
  padding-right: @space-0;
  .btn {
    width: 90%;
    margin: 10px auto;
  }
  .back-tip {
    margin-top: 30px;
    text-align: left;
    margin-left: 5%;
  }
}
</style>
