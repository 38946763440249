<template>
  <div class="login">
    <div class="title theme-color">
      仅限受邀客户申请
    </div>
    <van-cell-group>
      <van-field
        class="phone"
        input-align="right"
        v-model="mobile"
        placeholder="请输入手机号码"
        type="number"
        clearable
        maxlength="11"
        :formatter="formatterMobile"
      >
        <template #left-icon>
          <div class="label" @click="handleShowSheet">
            {{ showLabel }} <van-icon name="arrow-down" />
          </div>
        </template>
      </van-field>
      <van-field v-model="code" placeholder="请输入验证码" type="number" :formatter="formatterCode">
        <template #button>
          <van-button
            size="small"
            type="primary"
            :disabled="!mobile.length || count !== '' || loading"
            @click="validateGetCode"
            style="width: 80px;"
          >
            {{ count === "" ? "发送验证码" : count }}
          </van-button>
        </template>
      </van-field>
    </van-cell-group>
    <van-button type="primary" class="btn" @click="login" :disabled="!mobile || !code">
      验证并登录
    </van-button>
    <div></div>
    <van-action-sheet
      v-model:show="showSheet"
      :actions="actions"
      @select="onSelect"
      cancel-text="取消"
      close-on-click-action
    />
  </div>
</template>

<script>
import {
  Button,
  Field,
  CellGroup,
  Icon,
  Dialog,
  ActionSheet
} from "vant";
import { useStore } from "vuex";
import { onMounted, computed, ref } from "vue";
import util from "../utils/util";

const labelNameMap = {
  "+86": "中国大陆 +86",
  "+852": "中国香港 +852",
  "+853": "中国澳门 +853",
  "+886": "中国台湾 +886"
};
export default {
  name: "Home",
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [ActionSheet.name]: ActionSheet
  },
  data() {
    return {
      showSheet: false,
      dark: false,
      count: "",
      loading: false,
      actions: [
        { name: "中国大陆 +86", value: "+86" },
        { name: "中国香港 +852", value: "+852" },
        { name: "中国澳门 +853", value: "+853" },
        { name: "中国台湾 +886", value: "+886" }
      ]
    };
  },
  methods: {
    formatterMobile(value) {
      return this.$util.format_mobile(value);
    },
    formatterCode(value) {
      return this.$util.format_ver_code(value);
    },
    onSelect(item) {
      this.mobileLabel = item.value;
      this.showSheet = false;
    },
    handleShowSheet() {
      this.showSheet = true;
    },
    async validateGetCode() {
      if (this.count !== "") return;
      this.loading = true;
      const res = await this.$api.sendIsdaVerCode(`${this.mobileLabel}-${this.mobile}`);
      this.loading = false;
      if (!res) return;
      const { code, msg = "获取验证码失败" } = res;
      if (code === 200) {
        this.$toast("验证码已发送");
        this.count = 60;
        this.begincountDown();
      } else {
        this.$toast(msg);
      }
    },
    begincountDown() {
      this.countDownTimer = setInterval(() => {
        if (this.count <= 0) {
          clearInterval(this.countDownTimer);
          this.countDownTimer = null;
          this.count = "";
        } else {
          this.count -= 1;
        }
      }, 1000);
    },
    async login() {
      const res = await this.$api.loginIsda(`${this.mobileLabel}-${this.mobile}`, this.code);
      if (!res) return;
      const { code, msg } = res;
      if (code === 200) {
        this.$toast(msg || "登录成功");
        this.$store.dispatch("handleLogin", () => {
          this.$router.push({ path: '/isda/choose' });
        });
      } else {
        this.$toast(msg || "登录失败、请重试");
      }
    }
  },
  setup() {
    onMounted(() => {
      util.clearLocalVuex();
      const store = useStore();
      store.commit("setTitleValue", "开立账户申请");
      store.commit("setStepsShow", false);
      let message = "";
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isWeixin = userAgent.indexOf("micromessenger") !== -1;
      const isDingTalk = userAgent.indexOf("dingtalk") !== -1;
      const isUC = userAgent.indexOf("ucbrowser") !== -1;
      // 如果是PC打开，则提示使用手机打开
      const isPC = util.isPC();
      if (isPC) {
        message = `PC端暂不支持开户\n请使用手机打开该页面`;
      } else if (isWeixin || isDingTalk) {
        message = `${isWeixin ? "微信" : "钉钉"}暂不支持开户\n请使用系统浏览器或者chrome打开该页面`;
      } else if (isUC) {
        message = `UC浏览器暂不支持开户\n请使用系统浏览器或者chrome打开该页面`;
      }
      // 如果有提示消息
      if (message) {
        Dialog.alert({
          title: "说明",
          messageAlign: "center",
          showCancelButton: false,
          showConfirmButton: false,
          message,
          confirmButtonColor: "rgba(0,0,0,0.6)"
        });
      }
    });
    const mobileLabel = ref("+86");
    const showLabel = computed(() => labelNameMap[mobileLabel.value]);
    const mobile = ref("");
    const code = ref("");
    return {
      mobileLabel,
      showLabel,

      mobile,
      code
    };
  }
};
</script>

<style lang="less">
.login {
  margin-top: 30px;
  .phone {
    .label {
      display: flex;
    }
  }
  .title {
    color: @font-color-1;
    font-size: @font-size-2;
    margin-left: @space-2;
    margin-bottom: @space-4;
    display: flex;
    justify-content: left;
    align-items: center;
    .icon {
      line-height: @font-size-2;
      margin-left: @space-4;
    }
  }
  .btn {
    width: 80%;
    margin: @space-0 auto;
  }
}
</style>
