<template>
  <div class="home">
    <div>
      <van-image
        width="100px"
        height="100px"
        style="padding: 20px; padding-bottom: 0;"
        fit="contain"
        src="https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png"
      />
    </div>
    <div class="theme-color" style="margin-top: 15px; margin-bottom: 15px;">已提交</div>
    <div v-if="isLocal" class="tip theme-color">
      <span>我们在尽快审批，您可登录查询进展，审批后我们将短信通知，请留意！</span><br /><br />
      <span>完成线下见证，确认待邮寄材料无误后，将材料邮寄到我司</span><br />
      <span
        >邮寄材料：开户申请签字材料（稍后发送至您的邮箱：{{ email }}，请下载打印并签字）、
        身份证件复印件、住址证明、银行证明、专业投资者证明（仅限专业投资者）</span
      ><br />
      <span>收件地址：香港中环皇后大道中99号中环中心39楼5室</span><br />
    </div>
    <div v-else class="tip theme-color">
      <span>我们在尽快审批，您可登录查询进展，审批后我们将短信通知，请留意！</span><br /><br />
      <span v-if="!isLocal">
        <span>
          请确认往我司账户转账不少于1万的港币或等值美元 (<span
            class="link"
            @click="handleShowAccount"
            >收款账户</span
          >)</span
        ><br />
        <br />
      </span>
      <div class="theme-color" v-if="isMajor">
        <span>确认待邮寄材料无误后，将材料邮寄到我司</span><br />
        <span>邮寄材料：专业投资者证明原件</span><br />
        <span>收件地址：香港中环皇后大道中99号中环中心39楼5室</span><br />
      </div>
    </div>
    <van-button type="primary" style="width: 80%;margin: auto;" @click="handleGoCheck">
      查看当前有效资料
    </van-button>
  </div>
</template>

<script>
import { Image as VanImage, Dialog, Button } from "vant";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import httpApi from "../../utils/httpApi";

const alertMsg = `
注意：
1.必须用认证身份所绑定的银行卡转账不少于1万的港币或等值美元；
2.若未及时上传汇款凭证，后续请将凭证发送到customer-service@beevestsec.com，以便加快核实。

港币账户
银行名称： DBS Bank (Hong Kong) Limited
SWIFT码： DHBKHKHHXXX 
户口名称： BEEVEST SECURITIES LIMITED 
银行代码： 016
账户号码： 478-002183148
附注：“Client Account Name” & “Account Number”

美元账户
银行名称： DBS Bank (Hong Kong) Limited
SWIFT码： DHBKHKHHXXX 
户口名称： BEEVEST SECURITIES LIMITED - CLIENT AC
银行代码： 016
账户号码： 478-002183148
附注："“Client Account Name”"& “Account Number”
`;
export default {
  name: "Home",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button
  },
  data() {
    return {};
  },
  methods: {
    handleGoCheck() {
      this.$router.push({ path: "/check" });
    },
    handleShowAccount() {
      Dialog.alert({
        title: "收款账户",
        messageAlign: "left",
        message: alertMsg,
        confirmButtonColor: "rgba(0,0,0,0.6)"
      });
    }
  },
  setup() {
    const isLocal = ref(false);
    const isMajor = ref(false);
    const email = ref("");
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "提交完成");
      store.commit("setStepsShow", false);
      const result = await httpApi.getUploadImgesStatus();
      if (!result) return;
      isLocal.value = result.data.auth_type === "线下见证";
      isMajor.value = result.data.major_invester === "是";
      const email_res = await httpApi.getOpenAccountInfo();
      if (!email_res) return;
      email.value = email_res.data.email;
    });
    return {
      email,
      isLocal,
      isMajor
    };
  }
};
</script>

<style lang="less">
.tip {
  text-align: left;
  padding: @space-0;
  padding-top: 0;
}
</style>
