<template>
  <div class="search">
    <div class="image">
      <van-image
        width="100px"
        style="padding: 20px"
        height="100px"
        fit="contain"
        src="https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png"
      />
    </div>
    <div>已提交!</div>
    <van-button @click="handleGoIsda" class="btn" style="margin-top: 30px;"> TRS收益互换衍生品服务查看 </van-button>
    <van-button class="btn" @click="handleGoHome">
      回到首页
    </van-button>
  </div>
</template>

<script>
import { Image as VanImage, Button } from "vant";
import { useStore } from "vuex";
import { onMounted } from "vue";
import utils from "../../utils/util";

export default {
  name: "Home",
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
  },
  data() {
    return {
      isdaJump: this.$store.state.user.isdaJump,
    };
  },
  methods: {
    handleGoIsda() {
      this.$router.push({ path: "/isda/aggrement" });
    },
    handleGoHome() {
      if (this.isdaJump) {
        const url = `${window.location.protocol}//${utils.getCaHost()}/search/index`;
        window.location.replace(url);
      } else {
        this.$router.push({ path: "/isda/choose" });
      }
    },
  },
  setup() {
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "ISDA协议");
      store.commit("setStepsShow", false);
    });
    return {};
  },
};
</script>

<style lang="less">
.image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.search {
  padding-left: @space-0;
  padding-right: @space-0;
  .btn {
    width: 90%;
    margin: 10px auto;
  }
  .back-tip {
    margin-top: 30px;
    text-align: left;
    margin-left: 5%;
  }
}
</style>
